<template>
  <div :class="'validated-field ' + getClass">
    <span class="validated-field__wrapper" tabindex="0" @focus="focus">
      <AtomsFormsTextInput :type="props.type" v-model="props.modelValue" :pattern="props.pattern" placeholder="" @update:modelValue="valueUpdated($event)" @validate="validateRequest" @enter="submit" :disabled="props.disabled" ref="textInput" />
      <span class="validated-field__placeholder">{{ props.placeholder }}{{ props.required ? ' *' : '' }}</span>
      <div class="validated-field__icon">
        <AtomsImagesIcon v-if="!isValid" icon="pozor" class="text-error" />
        <AtomsImagesIcon v-if="shouldValidate && isValid" icon="ok" class="text-success" />
      </div>
      <MoleculesButtonsButton v-if="props.buttonText" :arrow-right="true" @click="submit">{{ $t('Odeslat') }}</MoleculesButtonsButton>
    </span>
    <span class="validated-field__error">{{ _errorMessage }}</span>
    <span class="validated-field__help">{{ props.helpMessage }}</span>
  </div>
</template>
<script setup>

const props = defineProps({
  type: String,
  modelValue: String,
  pattern: String,
  required: Boolean,
  placeholder: String,
  errorMessage: String,
  helpMessage: String,
  disabled: String,
  buttonText: String
});

const i18n = useI18n();
const analytics = useAnalytics();

const textInput = ref(null);
const isValid = ref(true);
const shouldValidate = ref(false);
const _errorMessage = ref(props.errorMessage);

let formFieldFillingStarted = false;

const getClass = computed(() => { return (shouldValidate.value ? (isValid.value ? 'validated-field--valid' : 'validated-field--invalid') : '') + (props.buttonText ? ' validated-field--with-button' : '') });

const emit = defineEmits(['onInvalid', 'update:modelValue', 'submit']);

const focus = () => {
  textInput.value.focus();

  if (!formFieldFillingStarted) {
    analytics.pushEvent('user_interaction', {
      interaction_name: 'form-field-filling-start',
      page_element_specification: props.placeholder,
      _clear: true
    });

    formFieldFillingStarted = true;
  }
}

const validate = (withoutAnalytics) => {

  shouldValidate.value = true;

  let validationResult = true;

  if (props.required && props.modelValue.length === 0) {
    validationResult = false;
    _errorMessage.value = i18n.t('Prosíme vyplňte');
  }

  if (props.modelValue.length > 0) {
    if (typeof props.pattern !== 'undefined') {
      validationResult &= new RegExp(`^${props.pattern}$`).test(props.modelValue);
    }
    else if (props.type === 'email') {
      validationResult &= new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(props.modelValue);

      const invalidDomains = [
        'seznam.cu',
        'senam.cu',
        'senam.cz',
        'gmail.cz',
        'email.com',
        'emal.cz',
        'sezmam.cz',
        'seznam.cy',
        'email.cy',
        'seznan.cz',
        'seynam.cz',
        'seynam.cy',
        'seznan.cz',
        'sezmam.cz',
        'ceznam.cz',
        'gmal.com',
        'sezna.cz',
        'gamil.com',
        'seznam.cz.cz',
        'seznem.cz',
        'seznam.com'
      ];

      for (let i in invalidDomains) {
        if (props.modelValue.indexOf('@') > -1 && props.modelValue.split('@')[1].indexOf(invalidDomains[i]) > -1) {
          validationResult = false;
          break;
        }
      }
    }

    if (typeof props.errorMessage !== 'undefined' && props.errorMessage) {
      _errorMessage.value = props.errorMessage;
    }
  }

  if (!validationResult) {
    emit('invalid');
  }

  if (!withoutAnalytics) {
    analytics.pushEvent('user_interaction', {
      interaction_name: 'form-field-filling-' + (validationResult ? 'success' : 'error'),
      page_element_specification: props.placeholder,
      page_element_value: props.modelValue,
      _clear: true
    });
  }

  isValid.value = validationResult;

  return validationResult;
}

const validateRequest = () => {
  validate();
}

const valueUpdated = value => {

  const originalValue = props.modelValue;

  emit('update:modelValue', value);

  // validate always when input has changed and the previous state was invalid
  if (originalValue !== value && !isValid.value) {

    nextTick(() => {
      validate(true);
    });
  }
}

const submit = () => {

  if (validate()) {
    emit('submit');

    shouldValidate.value = false;
  }
}

defineExpose({ validate, focus });

</script>
<style lang="postcss">
.validated-field {

  flex: 1;

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 0.3rem;
    border-radius: 5px;
    @apply border-2 border-silver transition-all;

    &:focus-within {
      @apply border-gray shadow-around;
    }
  }

  input {
    flex: 1;
    padding-top: 0.8rem;
    border: 0;
    min-width: 150px;
  }

  &__placeholder {
    position: absolute;
    left: 0.75rem;
    top: 0.85rem;
    pointer-events: none;
    transition: all ease-in-out 0.3s;
  }

  .validated-field__wrapper:focus-within .validated-field__placeholder,
  input:not(:placeholder-shown)+.validated-field__placeholder {
    top: 0.1rem;
    font-size: 0.7rem;
  }

  &__error,
  &__help {
    display: block;
    margin-left: 0.75rem;
    max-height: 0;
    overflow: hidden;
    font-size: 0.8rem;
  }

  &__help {
    transition: all ease-in-out 0.3s;
  }

  &__icon {
    margin-right: 1rem;
    margin-top: -0.5rem;
    @apply text-xl;
  }

  .validated-field__wrapper:focus-within~.validated-field__help {
    max-height: 6rem;
  }

  &--invalid {
    .text-input {
      @apply border-error;
    }

    .validated-field__error {
      max-height: 6rem;
      @apply text-error;
    }

    .validated-field__help {
      transition: none;
      max-height: 0 !important;
    }
  }

  &--valid {
    .validated-field__required {
      visibility: hidden;
    }

    .validated-field__help {
      transition: none;
      max-height: 0 !important;
    }
  }

  &--with-button {
    .validated-field {
      &__wrapper {
        @apply flex-wrap lg:flex-nowrap pt-0;

        .text-input {
          @apply lg:pt-4;
        }

        .validated-field__placeholder {
          @apply lg:top-[1.1rem];
        }

        .button {
          @apply flex-1 flex-grow-0 lg:flex-none;
          margin: -2px;
        }
      }
    }
  }
}
</style>